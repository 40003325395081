import React, { Component } from 'react';


import Header from './header/Header';
// import Main from './main/Main';
import Portfolio from './portfolio/Portfolio';
import Footer from './footer/Footer';


const items = [

  {
    title: 'MeGwise Ltd Website',
		img_name: 'mglogo-1',
		img_ext: 'jpg',
		alt: 'MeGwise Ltd',
    download:false,
		colorClass:'ruby-light',
		project_url: '//megwise.julia-julia.com/',
    categories:['html5','css3','jQuery','Javascript'],
		description: 'Dynamic web layout based on a fluid grid system created using jQuery and Mansonry. Responsive design approach to adapt the content to smaller screen.'
	},
  {
    title: 'Nessus UI',
		img_name: 'nessus',
		img_ext: 'jpg',
		alt: 'Nessus UI',
    download:false,
    colorClass:'plum-light',
		project_url: '//github.com/guglio/nessus-ui',
    categories:['React','css3','Material-UI','Javascript', 'NodeJS'],
		description: 'Web application that requests from a remote sever a list of hosts, and display them into a table. The data showed in the table is sortable (by all the columns) and searchable. There is also an additional page (Request) that change the number of hosts the user can request from the server.'
  },
  {
    title: 'Fibonacci',
		img_name: 'fibonacci',
		img_ext: 'jpg',
		alt: 'Fibonacci',
    colorClass:'grey',
    download:false,
		project_url: '//github.com/guglio/fibonacci',
    categories:['React','css3','MathJS','Javascript'],
		description: 'Web application to calculate the nth fibonacci number.'
  },
  {
    title: 'Arduino-timetable',
		img_name: 'Arduino-timetable',
		img_ext: 'jpg',
    download:false,
		colorClass:'plum-light',
		project_url: '//github.com/guglio/Arduino-timetable',
    categories: ['C','php','MySQL','html5','css3'],
		description: 'Software and hardware project based on Arduino with the implementation of JSON, MySQL server and NFC system.'
  },
  {
    title: 'DNA Secondary Structure Representation',
		img_name: 'DNA',
		img_ext: 'jpg',
    download:false,
    colorClass:'grey',
		project_url: '//dna.guglielmo-turco.com',
    categories: ['React','D3','html5','css3'],
		description: 'Web application that generates an interactive 2D representation of the molecule, given a DNA sequence and its 2D structure in dot-bracket notation (DBN)'
  },
	{
		title: 'ISO Quality Standard',
		img_name:'ISO',
		img_ext:'jpg',
		colorClass:'straw-light',
		alt:'ISO Quality Standard',
    download:false,
		categories: ['html5','css3','AngularJS','Bootstrap','CouchDB'],
		project_url: '//github.com/guglio/ISO_staff',
		description:'Internal web app to organize courses data and infrastructure maintenance schedules. Built with AngularJS, Bootstrap and CouchDB.'
	},
	{
    title: 'Neighborhood-Map - Udacity Nanodegree',
		img_name:'NeighborhoodMapProject',
		img_ext:'jpg',
		alt: 'Neighborhood-Map - Udacity Nanodegree',
    colorClass:'grey',
    download:false,
    categories:['html5','css3','jQuery','Javascript','AJAX','GoogleMaps API'],
		project_url: '//guglio.github.io/Neighborhood-Map/',
		description: 'Single-page application featuring a neighborhood map of San Diego, using of KnockoutJS framework. Implementation of Google Map API and New York Times API.'
	},
  {
    title: 'Giulia Mucciarelli',
    project_url: '//julia-julia.com',
    categories: ['html5','css3','Javascript','Bootstrap'],
		img_name:'GiuliaMucciarelli_2',
		img_ext:'jpg',
		colorClass:'ruby-light',
    download:false,
		description: "Customized portfolio website — minimalistic & responsive design."
  },
  {
    title: 'Cluster Legno Piemonte',
    categories:['html5','css3','jQuery','Javascript','php'],
		img_name:'clustersito-1',
		img_ext: 'jpg',
		colorClass:'grey',
    download:false,
		project_url:'http://clusterlegnopiemonte.guglielmo-turco.com/',
		description: "Website for the Organization Cluster Legno Piemonte, that collects latest news, meetings. Custom internal search engine based on php and mysql."
	},
	{
    title: 'iOS Salesforce app',
    categories: ['Sencha Toutch','AJAX','LAMP','MySQL','php','html5','css3','Apache Cordova'],
		img_name: 'salesforce_App',
		img_ext: 'jpg',
    download:false,
		colorClass:'ruby-light',
		project_url:'//github.com/guglio/salelsforce-app',
		description: 'Data Collection App developed using Sencha Touch and translated into native application with Apache Cordova. Data storage on local LAMP server.'
  },
	{
    title:'CIAM Ltd',
    categories:['html5','css3','jQuery','Javascript'],
		img_name:'CIAM',
		img_ext:'jpg',
    download:false,
		project_url:'//github.com/guglio/CIAM_test/',
		colorClass: 'straw-light',
		description: 'Institutinal company\'s website, created witht the direct collaboration with the internal design team. Currently under makeover'
  },

  {
    title: 'Victor Salvi Museum',
    img_name: 'salvi-1',
		img_ext: 'jpg',
		alt: 'Victor Salvi Musem',
    colorClass:'plum-light',
    download:false,
    categories:['html5','css3','jQuery','jQuery Mobile','Javascript','AJAX'],
		project_url: 'http://www.victorsalvimuseum.com/',
		description: "Designed user-centered website using custom JavaScript to handle the Museum news updates, and jQuery mobile to enhance responsiveness and cross-browser compatibility."
	}
	,
  {
    title: 'Studio Tecnico Degioanni',
		 img_name: 'degioannisito-1',
		 img_ext: 'jpg',
     download:false,
		 colorClass:'ruby-light',
		 project_url: 'http://www.studiodegioanni.com/',
		 categories:['html5','css3','jQuery','Javascript','php'],
		 description:'Crafted the client\'s idea, along side the Design Team. Developed a custom UI for administrative purpose (editing RSS, add/remove users to private area).'
  },
	{
		title: "Servette's mines - Saint-Marcel",
		categories:['Concept','Design','Sencha Toutch','html5','css3','Apache Cordova'],
		img_name:'servette-1',
		img_ext:'jpg',
		colorClass:'plum-light',
    download:true,
		project_url:'pdf/ServetteMockup.pdf',
		description: "Servette's mines museum app's mockups and exposition's iBeacon solution."
	},
  {
    title: 'S.I.C.A.M. Ltd',
		img_name: 'sicam-1',
		img_ext: 'jpg',
		colorClass:'grey',
    download:false,
		project_url: 'http://officine-sicam.guglielmo-turco.com/',
    categories:['html5','css3','jQuery','Javascript'],
		description:'Institutinal company\'s website, created witht the direct collaboration with the internal design team.'
  },
	{
    title: 'Internal Webmail Platform',
    categories: ['html5','css3','jQuery','Javascript','php','MySQL'],
		img_name: 'webmail',
		img_ext: 'jpg',
    download:false,
		colorClass:'straw-light',
		project_url:'//github.com/guglio/Internal-Webmail-Platform',
		description: "Working concept of Company's internal web-interface to manage company's domains emails. Adoption of php, jQuery mobile, mysql and PHPMailer class to develop the all environmental pieces."
  },
	{
    title: 'KARIM Ltd',
    categories:['html5','css3','jQuery','Javascript'],
		img_name:'KARIM',
		img_ext:'jpg',
    download:false,
		colorClass:'plum-light',
		project_url: 'http://karimsro.guglielmo-turco.com/',
		description:'Institutinal company\'s website, created witht the direct collaboration with the internal design team.'
  }
]

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 420;
      if (isTop !== this.state.isTop) {
          this.setState({isTop : isTop});
      }

    });
  }

  render() {
    return (
      <div>
        <Header isTop={this.state.isTop}></Header>
        {/* <Main isTop={this.state.isTop}/> */}
        <Portfolio items={items} isTop={this.state.isTop}/>
        <Footer />
      </div>
    );
  }
}

export default App;
