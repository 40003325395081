import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
   } from 'reactstrap';
  import { Link } from 'react-scroll'

import './Header.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }



  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const { isTop } = this.props;
    let myEmail = 'guglielmo.turco@gmail.com';
    return (
      <div>
        <header className="text-center">
          <div className="header-wrapper">
      			<h1>Hello,<br/>I'm Guglielmo Turco</h1>
          </div>
        </header>
        <Navbar expand="md" className={`navbar-custom ${isTop ? "" : "fixed-top"}`}>
          <NavbarBrand href="//guglielmo-turco.com">Guglielmo Turco</NavbarBrand>
          <p className="social">
            <a href="//www.linkedin.com/in/guglielmo-turco" className="icon-linkedin" target="_blank" aria-label="Linkedin profile" rel="noopener noreferrer"> </a>
            <a href="//github.com/guglio" className="icon-github" target="_blank" aria-label="Github profile" rel="noopener noreferrer"> </a>
            <a href="//twitter.com/gugliolo" className="icon-twitter" target="_blank" aria-label="Twitter profile" rel="noopener noreferrer"> </a>
      		  <a href={`mailto:${myEmail}`} className="icon-email" aria-label="Email" rel="noopener noreferrer"> </a>
          </p>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>

            <Nav className="ml-auto" navbar>
              {/* <NavItem>
                <Link
                  className="nav-link"
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={300}
                  activeClass="active"
                  offset={-48}
                >
                  About
                </Link>
              </NavItem> */}
              <NavItem>
                <Link
                  className="nav-link"
                  to="portfolio"
                  spy={true}
                  smooth={true}
                  duration={300}
                  activeClass="active"
                  offset={-48}
                >
                  Portfolio
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to="connect"
                  spy={true}
                  smooth={true}
                  duration={300}
                  activeClass="active"
                  offset={-48}
                >
                  Connect
                </Link>
              </NavItem>
            </Nav>

          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;
