import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component{

  render(){
    let myEmail = 'guglielmo.turco@gmail.com';

    return(
      <footer id="connect">
        <div className="container-fluid text-center">
          <h2 className="text-center text-uppercase">Get in touch</h2>
          <a href={`mailto:${myEmail}`} className="btn btn-default btnMail"><span className="myEmail" data-email="true">{myEmail}</span></a>
          <p>
            <a href="//www.linkedin.com/in/guglielmo-turco" className="icon-linkedin" target="_blank" aria-label="Linkedin profile" rel="noopener noreferrer"> </a>
            <a href="//github.com/guglio" className="icon-github" target="_blank" aria-label="Github profile" rel="noopener noreferrer"> </a>
            <a href="//twitter.com/gugliolo" className="icon-twitter" target="_blank" aria-label="Twitter profile" rel="noopener noreferrer"> </a>
      		  <a href={`mailto:${myEmail}`} className="icon-email" aria-label="Email" rel="noopener noreferrer"> </a>
          </p>
          <p>
            <small>© {(new Date()).getFullYear()} <a href="//guglielmo-turco.com">Guglielmo Turco</a>. All rights reserved.</small>
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer;
