import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';

import './Portfolio.css';

class Portfolio extends Component{
  render() {
    const { items, isTop } = this.props;
    const colorScheme = ['ruby-light','plum-light','grey','straw-light'];
    return (
      <div id="portfolio">
        {/* <h2 className="text-uppercase text-center">Portfolio</h2> */}
        <Container fluid className={`portfolio-container ${isTop ? "" : "clearFixHeader"}`}>
          <Row className="d-flex">
        {
          items.map((item,index) => (
            <Col md="4" sm="6" xs="12" className={`portfolio-item d-flex flex-column ${item.colorClass ? item.colorClass : colorScheme[index%4]}`} key={index}>
              <a href={item.project_url} target='_blank' rel='noopener noreferrer' download={item.download} >
                  <img src={`/data/images/portfolio/${item.img_name}@2x.${item.img_ext}`} alt={`${item.alt}`} className="img-fluid mx-auto project-img rounded" />
              </a>
              <h3 className="text-center">
                <a href={item.project_url} target="_blank">{item.title}</a>
              </h3>
              <h4 className="text-center text-light categories_subtitle">
                {
                  item.categories.map((category,i) => (
                    <span key={category+i}>{category}</span>
                  ))
                }
              </h4>
              <p>{item.description}</p>
            </Col>
          ))
        }
        </Row>
        </Container>
      </div>
    )
  }
}

export default Portfolio;
